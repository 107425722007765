import * as React from 'react';
import clsx from 'clsx';
import styles from './ts.module.css';
import './ts.css';
import Tabs from '../work-proccess';
import { Gokada, EveryPig, Backbone, KoroBox, Dokka } from 'components/carousel';

function TS() {
  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [prevSlide, setPrevSlide] = React.useState(0);

  const handleTabsChange = (idx: number) => {
    setPrevSlide(currentSlide);
    setCurrentSlide(idx);
  };

  return (
    <section className="section">
      <div className={clsx('inner-container', styles.container)}>
        <h2 className={clsx('title', styles.afterWave)}>Some of the domains we empower</h2>
        <Tabs onChange={handleTabsChange} />
      </div>

      <div className={styles.slidesContainer}>
        {[<EveryPig />, <Dokka />, <Gokada />, <KoroBox />, <Backbone />].map((slide, index) => (
          <div
            className={clsx('main-page-slides', styles.slide, {
              [styles.slide_visible]: currentSlide === index,
              [styles.slide_right]: currentSlide < index,
              [styles.slide_left]: currentSlide > index,
              [styles.slide_transition]: index === prevSlide || index === currentSlide,
            })}
            key={index}
          >
            {slide}
          </div>
        ))}
      </div>
    </section>
  );
}

export default TS;
